import React, { useEffect } from 'react';
import './BorrowRequest.css';

const BorrowRequest = ({
  amount,
  setAmount,
  duration,
  setDuration,
  interestRate,
  setInterestRate,
  cryptoData,
  selectedCollateral,
  setSelectedCollateral,
  requiredCollateral,
  setRequiredCollateral,
  showDropdown,
  setShowDropdown,
}) => {
  useEffect(() => {
    if (amount && selectedCollateral) {
      const ltv = cryptoData.find(crypto => crypto.name === selectedCollateral)?.ltv || 50;
      const numericAmount = Number(amount);
      if (!isNaN(numericAmount)) {
        setRequiredCollateral((numericAmount / (ltv / 100)).toFixed(0)); // No decimals
      }
    }
  }, [amount, selectedCollateral, cryptoData, setRequiredCollateral]);

  const handleCollateralSelect = (crypto) => {
    setSelectedCollateral(crypto.name);
    setShowDropdown(false);
  };

  const formatCurrency = (value) => {
    return value ? `$${parseFloat(value).toLocaleString()}` : '';
  };

  return (
    <div className="borrow-request">
      <label className="bold-text">Amount to Borrow (USD)</label>
      <input
        type="number"
        placeholder="Enter amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <label className="bold-text">Duration (Months)</label>
      <input
        type="number"
        placeholder="Enter duration"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
      />
      <label className="bold-text">Interest Rate: {interestRate}%</label>
      <input
        type="range"
        min="1"
        max="100"
        value={interestRate}
        onChange={(e) => setInterestRate(e.target.value)}
      />
      <div className="collateral-section">
        <label className="bold-text">Collateral</label>
        <div
          className="custom-dropdown"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <div className="selected-option">
            {selectedCollateral || 'Select Collateral'}
          </div>
          {showDropdown && (
            <div className="dropdown-options">
              {cryptoData.map((crypto) => (
                <div
                  key={crypto.name}
                  className="dropdown-option"
                  onClick={() => handleCollateralSelect(crypto)}
                >
                  <img src={crypto.logo} alt={crypto.name} className="crypto-logo" />
                  <span>{crypto.name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {selectedCollateral && requiredCollateral && (
          <div className="collateral-details">
            <p>
              {formatCurrency(requiredCollateral)} worth of{' '}
              <strong>{selectedCollateral}</strong>.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BorrowRequest;
