import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import UserButtons from "../UserButtons/UserButtons";
import adv from "../../images/credita-home.png";
import "./CreditaPlatform.css";

const CoinPlatform = () => {
  return (
    <Container fluid className="coinplatform-container">
      <Row className="coinplatform-row">
        <Col xs={0} md={5} className="d-none d-md-block">
          <img
            src={adv}
            alt="landing hero"
            className="coinplatform-hero-image"
          />
        </Col>
        <Col md={1} />
        <Col xs={12} md={5} className="coinplatform-content">
          <h2 className="coinplatform-title">Step into a world of wealth opportunities.</h2>
          <h5 className="coinplatform-subtitle">
            Borrow and Lend money at your own terms keeping digial assets as collateral
          </h5>
          <div className="coinplatform-buttons">
            <UserButtons />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CoinPlatform;
