import React, { useState } from 'react';
import './UserPayment.css';

const UserPayment = ({ payment }) => {
    const [showModal, setShowModal] = useState(false);
    const [iban, setIban] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleAddPayment = () => {
        if (payment.length >= 3) {
            setErrorMessage('You can only add a maximum of 3 payment methods.');
            return; // Do not proceed with adding the payment if limit is reached
        }

        // Logic for adding payment (e.g., save IBAN and Name)
        console.log("Payment Added:", { iban, name });
        setShowModal(false); // Close the modal after adding
        setErrorMessage(''); // Reset the error message if the payment is added successfully
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setErrorMessage(''); // Reset the error message when closing the modal
    };

    return (
        <div className="user-payment">
            <h2>Your Payment Details</h2>
            
            {(!payment || payment.length === 0) ? (
                <div className="no-payment">
                    <p>You don’t have any payment methods added. Add one for faster checkout.</p>
                    <button 
                        className="add-payment-btn" 
                        onClick={() => setShowModal(true)}
                    >
                        Add Payment Method
                    </button>
                </div>
            ) : (
                <>
                    <button 
                        className="add-payment-btn" 
                        onClick={() => setShowModal(true)} 
                        disabled={payment.length >= 3}
                    >
                        Add Payment Method
                    </button>

                    {payment.map((pay, index) => (
                        <div key={index} className="payment-detail">
                            <p><strong>Payment Type:</strong> {pay.paymentType}</p>
                            <p><strong>IBAN:</strong> {pay.IBAN}</p>
                            {pay.primary === "true" && <span className="primary-badge">Primary</span>}
                        </div>
                    ))}
                </>
            )}

            {/* Error Message */}
            {errorMessage && <p className="error-message">{errorMessage}</p>}

            {/* Modal Popup */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Add Payment Method</h3>
                        <label>IBAN:</label>
                        <input 
                            type="text" 
                            value={iban} 
                            onChange={(e) => setIban(e.target.value)} 
                            placeholder="Enter IBAN"
                        />
                        <label>Name:</label>
                        <input 
                            type="text" 
                            value={name} 
                            onChange={(e) => setName(e.target.value)} 
                            placeholder="Enter Name"
                        />
                        <div className="modal-buttons">
                            <button onClick={handleAddPayment}>Add Payment</button>
                            <button onClick={handleCloseModal}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPayment;
