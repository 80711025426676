import React from 'react';
import { useParams } from 'react-router-dom';
import './TransactionView.css'; // Ensure the correct path to the CSS file

const TransactionView = ({ transactions }) => {
    const { orderId } = useParams();
    const transaction = transactions.find((t) => t.orderId === orderId);

    if (!transaction) {
        return (
            <div className="transaction-view not-found">
                Transaction not found.
            </div>
        );
    }

    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
            case 'inprogress':
                return 'status status-inprogress';
            case 'completed':
                return 'status status-completed';
            case 'cancelled':
                return 'status status-cancelled';
            default:
                return 'status';
        }
    };

    return (
        <div className="transaction-view">
            <h2>Transaction Details</h2>
            <h3>Order ID: {transaction.orderId}</h3>
            <table>
                <tbody>
                    <tr>
                        <td>Type:</td>
                        <td>{transaction.type}</td>
                    </tr>
                    <tr>
                        <td>Amount:</td>
                        <td>{transaction.amount} {transaction.currency}</td>
                    </tr>
                    <tr>
                        <td>Duration:</td>
                        <td>{transaction.duration} days</td>
                    </tr>
                    <tr>
                        <td>Interest:</td>
                        <td>{transaction.interest}%</td>
                    </tr>
                    <tr>
                        <td>Collateral:</td>
                        <td>{transaction.collateral.join(', ')}</td>
                    </tr>
                    <tr>
                        <td>Status:</td>
                        <td>
                            <span className={getStatusClass(transaction.status)}>
                                {transaction.status}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>Borrower:</td>
                        <td>{transaction.borrowerDetails?.firstName || 'N/A'}</td>
                    </tr>
                </tbody>
            </table>

            {transaction.transactionHistory && transaction.transactionHistory.length > 0 && (
                <div className="payment-history">
                    <h3>Payment History</h3>
                    <table>
                        <thead>
                            <tr>
                                <th>Transaction Type</th>
                                <th>Date</th>
                                <th>Amount</th>
                                <th>Payment Provider</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transaction.transactionHistory.map((history, index) => (
                                <tr key={index}>
                                    <td>{history.transcationType}</td>
                                    <td>{history.createdDate}</td>
                                    <td>{history.amount}</td>
                                    <td>{history.paymentProvider}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default TransactionView;
