import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./UserButtons.module.css";

const UserButtons = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/marketplace");
  };

  return (
    <>
      <div className="d-flex gap-4">
        <Button
          className={`rounded-pill ${styles.btnLogin}`}
          variant="primary"
          size="lg"
          onClick={handleNavigation}
        >
          Get Started
        </Button>
      </div>
    </>
  );
};

export default UserButtons;
