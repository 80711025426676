import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./CreditaInvestments.css";
import landingMf from "../../images/landingMf.svg";
import landingGsec from "../../images/landingGsec.svg";
import landingCb from "../../images/landingCb.svg";
import landingEtf from "../../images/landingEtf.svg";
import InvestmentOption from "../InvestmentOption/InvestmentOption";

const CoinInvestments = () => {
  return (
    <>
      <Container className="pt-5 pb-5">
        <Row className="text-center mb-4">
          <Col>
            <h4 className={styles.mainHeading}>
              Many Lending and Borrowing options
            </h4>
            <h5 className="text-secondary">
              One platform for all your money needs
            </h5>
          </Col>
        </Row>
        {/* Investment Options */}
        <InvestmentOption
          imgSrc={landingMf}
          imgWidth="80"
          imgHeight="80"
          heading="Unlock liquidity with a digital asset"
          description="decentralized marketplace where users can borrow and lend fiat currency on clearly
defined and accessible terms."
        />
        <InvestmentOption
          imgSrc={landingGsec}
          imgWidth="80"
          imgHeight="80"
          heading="Risk free Lending on your own terms"
          description=" Most DeFi platforms, where conditions are preordained, Credita
extends complete freedom to its users in setting their preferred interest rates,
schedules of repayment, and other terms. "
        />
        <InvestmentOption
          imgSrc={landingCb}
          imgWidth="80"
          imgHeight="80"
          heading="For evenyone"
          description="Wealth solutions for family offices, corporate, and private clients."
  
        />
      </Container>
    </>
  );
};

export default CoinInvestments;
