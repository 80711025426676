import React, { useEffect, useState } from "react";
import "./UserAccount.css";
import UserPayment from "./UserPayment/UserPayment";
import UserTransactions from "./UserTransactions/UserTransactions";
import UserDetails from "./UserDetails/UserDetails"; // Import UserDetails component

const UserAccount = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [transactions, setTransactions] = useState([]); // State for storing transactions

  useEffect(() => {
    // Fetch user details from the mock JSON endpoint
    setUserDetails({
      username: localStorage.getItem("username"),
      profilePhoto: localStorage.getItem("picture"),
      email: localStorage.getItem("email"),
    });
  }, []);

  useEffect(() => {
    if (userDetails) {
      // Fetch user transactions once user details are available
      fetch(`/data/userTransaction.json`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setTransactions(data.transactions || []);
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
        });
    }
  }, [userDetails]);

  const totalTransactions = transactions.length;
  const totalLends = transactions
    .filter((t) => t.type === "LEND")
    .reduce((acc, t) => acc + parseFloat(t.amount), 0);
  const totalBorrows = transactions
    .filter((t) => t.type === "BORROW")
    .reduce((acc, t) => acc + parseFloat(t.amount), 0);

  return (
    <>
      <h1 className="greeting">Hello {userDetails?.username || "User"}</h1>
      <div className="account">
        {userDetails && (
          <>
            <UserDetails
              user={userDetails}
              totalTransactions={totalTransactions}
              totalLends={totalLends}
              totalBorrows={totalBorrows}
            />
            <UserPayment payment={userDetails.payment} />
          </>
        )}
      </div>
      <div className="transactions">
        {/* Pass transactions to UserTransactions */}
        <UserTransactions transactions={transactions} />
      </div>
    </>
  );
};

export default UserAccount;
