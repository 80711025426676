import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./ArenaDetails.css";

const ArenaDetails = () => {
  const location = useLocation();
  const deal = location.state;

  const [cryptoData, setCryptoData] = useState([]);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Load the cryptocurrency data from the JSON file
  useEffect(() => {
    fetch("/data/cryptoData.json")
      .then((response) => response.json())
      .then((data) => setCryptoData(data))
      .catch((error) => {
        console.error("Error loading crypto data:", error);
      });
  }, []);

  // Fallback for collateral logo
  const getLogoUrl = (collateral) => {
    const match = cryptoData.find((crypto) =>
      crypto.name.toLowerCase().includes(collateral.toLowerCase())
    );
    return match?.img || "/default-logo.png";
  };

  if (!deal) {
    return <div className="error-message">Error: No details found for this request.</div>;
  }

  const renderAction = () => {
    if (deal.type.toLowerCase() === "lend") {
      return (
        <div className="action-container">
          <p>
          If you find the offer appealing, you can proceed with deploying the funds. Once the payment is successfully processed, you can monitor it in the orders section.
          </p>
          <button className="pay-button">Pay Now</button>
        </div>
      );
    } else if (deal.type.toLowerCase() === "borrow") {
      return (
        <div className="action-container">
          <p>
          Provide the required collateral, and the requested amount will be credited to your primary bank account within 6 hours.
          </p>
          <button className="pay-button">Deploy</button>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="arena-details-container">
      <div className="card-wrapper">
        <h2 className="arena-title">Request Details</h2>
        <table className="arena-details-table">
          <tbody>
            <tr>
              <th>User</th>
              <td>{deal.user_name}</td>
            </tr>
            <tr>
              <th>Amount</th>
              <td>${deal.amount}</td>
            </tr>
            <tr>
              <th>Duration</th>
              <td>{deal.duration} months</td>
            </tr>
            <tr>
              <th>Interest (%)</th>
              <td>{deal.interest}%</td>
            </tr>
            <tr>
              <th>Collateral</th>
              <td>
             
                  <img
                    src={getLogoUrl(deal.collateral)}
                    alt={deal.collateral}
                    className="collateral-logo"
                  />
                  {deal.collateral}
              </td>
            </tr>
            <tr>
              <th>Type</th>
              <td>{deal.type.toLowerCase()}</td>
            </tr>
          </tbody>
        </table>
        {renderAction()}
      </div>
    </div>
  );
};

export default ArenaDetails;
