import React, { useState, useEffect } from "react";
import "./NewsSection.css";

const NewsSection = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    // Fetch the news data from a local JSON file or API
    fetch("/data/news.json")
      .then((response) => response.json())
      .then((data) => setNewsData(data.data)) // Adjusted to access the nested `data` array in JSON
      .catch((error) => console.error("Error fetching news data:", error));
  }, []);

  return (
    <div className="news-section">
      <h1 className="news-heading">Cryptocurrency Talk</h1>
      <div className="news-grid">
        {newsData.map((news, index) => (
          <div key={index} className="news-card">
            <img
              src={news.image_url}
              alt={news.title}
              className="news-card-image"
            />
            <div className="news-card-content">
              <div className="news-category">
                {news.topics.length > 0 ? news.topics.join(", ") : "General"}
              </div>
              <h3 className="news-title">{news.title}</h3>
              <p className="news-source">{news.source_name}</p>
              <p className="news-time">{new Date(news.date).toLocaleString()}</p>
              <a
                href={news.news_url}
                target="_blank"
                rel="noopener noreferrer"
                className="news-link"
              >
                 More
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsSection;
