import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './UserTransactions.css';

const UserTransactions = ({ transactions }) => {
    const [filter, setFilter] = useState('');

    const statusMap = {
        INPROGRESS: 'In Progress',
        COMPLETED: 'Completed',
        CANCELLED: 'Cancelled',
    };

    const filteredTransactions = transactions.filter((transaction) => {
        if (filter === '') return true;
        return transaction.type.toLowerCase() === filter.toLowerCase();
    });

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    return (
        <div className="user-transactions">
            <h2>Your Transactions</h2>
            <div className="filter-container">
                <label>
                    <input
                        type="radio"
                        name="filter"
                        value=""
                        checked={filter === ''}
                        onChange={handleFilterChange}
                    />
                    All
                </label>
                <label>
                    <input
                        type="radio"
                        name="filter"
                        value="lend"
                        checked={filter === 'lend'}
                        onChange={handleFilterChange}
                    />
                    Lend
                </label>
                <label>
                    <input
                        type="radio"
                        name="filter"
                        value="borrow"
                        checked={filter === 'borrow'}
                        onChange={handleFilterChange}
                    />
                    Borrow
                </label>
            </div>
            <table className="transaction-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Order ID</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Duration</th>
                        <th>Interest</th>
                        <th>Collateral</th>
                        <th>Status</th>
                        <th>Borrower</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredTransactions.map((transaction) => (
                        <tr key={transaction.id}>
                            <td>{transaction.type}</td>
                            <td>
                                <Link to={`/transaction/${transaction.orderId}`}>{transaction.orderId}</Link>
                            </td>
                            <td>{transaction.amount}</td>
                            <td>{transaction.currency}</td>
                            <td>{transaction.duration} days</td>
                            <td>{transaction.interest}%</td>
                            <td>{transaction.collateral.join(', ')}</td>
                            <td>
                                <span className={`status status-${transaction.status.toLowerCase()}`}>
                                    {statusMap[transaction.status] || transaction.status}
                                </span>
                            </td>
                            <td>{transaction.borrowerDetails?.firstName || 'N/A'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default UserTransactions;
