import React, { useState, useEffect } from 'react';
import './RequestPage.css';
import LendRequest from './Lend/LendRequest';
import BorrowRequest from './Borrow/BorrowRequest';
import config from "../../config";

const RequestPage = () => {
  const [requestType, setRequestType] = useState('Borrow');
  const [amount, setAmount] = useState('');
  const [duration, setDuration] = useState('');
  const [interestRate, setInterestRate] = useState(5);
  const [cryptoData, setCryptoData] = useState([]);
  const [collateral, setCollateral] = useState([]);
  const [requiredCollateral, setRequiredCollateral] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [bearerToken, setBearerToken] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    if (token) {
      setBearerToken(token);
    }

    fetch('/data/borrowCrypto.json')
      .then((response) => response.json())
      .then((data) => setCryptoData(data))
      .catch((error) => console.error('Error fetching crypto data:', error));
  }, []);

  const handleRequestTypeChange = (type) => {
    setRequestType(type);
    setAmount('');
    setDuration('');
    setInterestRate(5);
    setCollateral([]);
    setRequiredCollateral(null);
  };

  const handleSubmit = async () => {
    // Validation checks
    const numericAmount = parseFloat(amount);
    const numericDuration = parseInt(duration, 10);

    if (isNaN(numericAmount) || numericAmount < 0) {
      alert('Amount must be a number starting from 0 and cannot be negative.');
      return;
    }

    if (isNaN(numericDuration) || numericDuration < 1) {
      alert('Duration must be a number starting from 1 and cannot be negative.');
      return;
    }

    if (!bearerToken) {
      alert('User not authenticated');
      return;
    }

    const requestData = {
      amount: numericAmount,
      duration: numericDuration,
      interest: parseFloat(interestRate),
      collateral: collateral.length > 0 ? collateral : ['None'],
    };

    const url =
      requestType === "Borrow"
        ? `${config.baseUrl}/v1/credita/new/borrow`
        : `${config.baseUrl}/v1/credita/new/lend`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Request failed');
      }

      const result = await response.json();
      alert('Request submitted successfully');
      console.log(result);
    } catch (error) {
      alert('Error submitting request');
      console.error('Error:', error);
    }
  };

  return (
    <div className="request-page">
      <h1 className="page-title">Raise a New Request</h1>
      <div className="mode-selector">
        {['Borrow', 'Lend'].map((type) => (
          <button
            key={type}
            className={`mode-option ${requestType === type ? 'active' : ''}`}
            onClick={() => handleRequestTypeChange(type)}
          >
            {type}
          </button>
        ))}
      </div>
      <div className="form-container">
        <div className="form-content">
          {requestType === 'Borrow' ? (
            <BorrowRequest
              amount={amount}
              setAmount={setAmount}
              duration={duration}
              setDuration={setDuration}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              cryptoData={cryptoData}
              selectedCollateral={collateral}
              setSelectedCollateral={setCollateral}
              requiredCollateral={requiredCollateral}
              setRequiredCollateral={setRequiredCollateral}
              showDropdown={showDropdown}
              setShowDropdown={setShowDropdown}
            />
          ) : (
            <LendRequest
              amount={amount}
              setAmount={setAmount}
              duration={duration}
              setDuration={setDuration}
              interestRate={interestRate}
              setInterestRate={setInterestRate}
              collateral={collateral}
              setCollateral={setCollateral}
            />
          )}
        </div>
        <div className="info-container">
          <div className="info-box">
            {requestType === 'Borrow' ? (
              <div>
                <h3 className="bold-text">Borrow Details</h3>
                <p><span className="bold-text">Amount you want to borrow:</span> {amount || ''}</p>
                <p><span className="bold-text">Loan term:</span> {duration || ''} months</p>
                <p><span className="bold-text">Interest rate:</span> {interestRate}%</p>
                <p><span className="bold-text">Origination fee 1.5%:</span> ${(amount * 0.015).toFixed(2) || ''} USD</p>
                <p><span className="bold-text">Collateral required:</span> {requiredCollateral || ''}</p>
              </div>
            ) : (
              <div>
                <h3 className="bold-text">Lend Details</h3>
                <p><span className="bold-text">Amount you want to lend:</span> {amount || ''}</p>
                <p><span className="bold-text">Loan term:</span> {duration || ''} months</p>
                <p><span className="bold-text">Interest rate:</span> {interestRate}%</p>
                <p><span className="bold-text">Collateral accepted:</span> {collateral.join(', ') || 'None'}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="next-button-container">
        <button className="next-button" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default RequestPage;
