import React, { useEffect, useState } from "react";
import "./CryptoNewsCard.css";

const CryptoNewsCard = () => {
  const [cryptoData, setCryptoData] = useState([]);

  useEffect(() => {
    // Fetch data (replace with your actual API or local JSON file)
    fetch("/data/cryptoData.json")
      .then((response) => response.json())
      .then((data) => setCryptoData(data))
      .catch((error) => console.error("Error fetching crypto data:", error));
  }, []);

  return (
    <div className="crypto-news-card">
      <div className="scrolling-container">
        <div className="scrolling-content">
          {cryptoData.map((coin, index) => (
            <div key={index} className="news-card">
              <img src={coin.img} alt={`${coin.name} logo`} className="coin-logo" />
              <div className="coin-details">
                <p className="coin-name">{coin.name}</p>
                <p className="coin-price">{coin.price}</p>
                <p
                  className="coin-change"
                  style={{
                    color: coin.change >= 0 ? "#16c784" : "#ea3943",
                  }}
                >
                  {coin.change >= 0 ? "▲" : "▼"} {coin.change}%
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CryptoNewsCard;
