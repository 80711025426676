import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import routes from "../../routes/routes.json";
import creditaLogo from "../../images/Primary color.svg";
import "./TopBar.css";

const TopBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [picture, setPicture] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const accessToken = queryParams.get("access_token");
    const username = queryParams.get("username");
    const pictureUrl = queryParams.get("picture");
    const email = queryParams.get("email");

    if (accessToken && username && pictureUrl) {
      localStorage.setItem("accessToken", accessToken);
      localStorage.setItem("username", username);
      localStorage.setItem("picture", pictureUrl);
      localStorage.setItem("email", email);

      setUser(username);
      setPicture(pictureUrl);
      navigate(routes.HOME);
    } else {
      const storedUsername = localStorage.getItem("username");
      const storedPicture = localStorage.getItem("picture");

      if (storedUsername) setUser(storedUsername);
      if (storedPicture) setPicture(storedPicture);
    }
  }, [navigate]);

  const handleLogout = () => {
    localStorage.clear();
    setShowDropdown(false);
    navigate(routes.HOME);
  };

  const handleProfileClick = () => {
    setShowDropdown(false);
    navigate(routes.USER_ACCOUNT);
  };

  const isActive = (path) => (location.pathname === path ? "active" : "");

  return (
    <Navbar expand="md" bg="light" className="topbar">
      <div className="container">
        <Navbar.Brand>
          <NavLink to={routes.HOME}>
            <img
              src={creditaLogo}
              alt="Credita Logo"
              className="navbar-logo"
              style={{ height: "30px" }}
            />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          <Nav>
            <Nav.Link as={NavLink} to={routes.MARKETPLACE} className={isActive(routes.MARKETPLACE)}>
              Arena
            </Nav.Link>
            {user && (
              <Nav.Link as={NavLink} to={routes.REQUESTS} className={isActive(routes.REQUESTS)}>
                Requests
              </Nav.Link>
            )}
            <Nav.Link as={NavLink} to={routes.NEWS} className={isActive(routes.NEWS)}>
              News
            </Nav.Link>
            <Nav.Link as={NavLink} to={routes.ABOUTUS} className={isActive(routes.ABOUTUS)}>
              About Us
            </Nav.Link>
            {user ? (
              <div className="profile-container">
                <img
                  src={picture}
                  className="profile-photo"
                  alt={`${user}'s profile`}
                  onClick={() => setShowDropdown((prev) => !prev)}
                />
                {showDropdown && (
                  <div className="profile-dropdown">
                    <div onClick={handleProfileClick}>Profile</div>
                    <div onClick={handleLogout}>Logout</div>
                  </div>
                )}
              </div>
            ) : (
              <Nav.Link as={NavLink} to={routes.LOGIN} className={isActive(routes.LOGIN)}>
                Login
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default TopBar;
