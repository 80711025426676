import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import News from "../pages/News/News";
import Home from "../pages/Home/Home";
import Login from "../pages/Login";
import MasterLayout from "../pages/MasterLayout";
import MarketPlace from "../pages/MarketPlace/MarketPlace";
import UserAccount from "../components/UserAccount/UserAccount";
import RequestPage from "../components/Requests/RequestPage";
import AboutUs from "../components/AboutUs/AboutUs";
import routes from "./routes.json";
import ArenaDetails from "../components/ArenaCard/ArenaDetails/ArenaDetails";
import TransactionView from "../components/UserAccount/TransactionView/TransactionView";

const PageRoutes = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await fetch("/data/userTransaction.json");
        if (!response.ok) {
          throw new Error("Failed to fetch transactions");
        }
        const data = await response.json();
        // Ensure data is an array before setting it to state
        if (Array.isArray(data)) {
          setTransactions(data);
        } else if (data.transactions && Array.isArray(data.transactions)) {
          setTransactions(data.transactions);
        } else {
          console.error("Transactions data is not in the expected format:", data);
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <>
      <Routes>
        <Route path={routes.HOME} element={<MasterLayout />}>
          <Route index element={<Home />} />
          <Route path={routes.NEWS} element={<News />} />
          <Route path={routes.MARKETPLACE} element={<MarketPlace />} />
          <Route path={routes.USERACCOUNTS} element={<UserAccount />} />
          <Route path={routes.REQUESTS} element={<RequestPage />} />
          <Route path={routes.ABOUTUS} element={<AboutUs />} />
          <Route path={routes.TRANSACTION_DETAIL} element={<TransactionView transactions={transactions} />} />
          <Route path={routes.ARENA_DETAIL} element={<ArenaDetails />} />
        </Route>
      </Routes>

      <Routes>
        <Route path={routes.LOGIN} element={<Login />} />
      </Routes>
    </>
  );
};

export default PageRoutes;
