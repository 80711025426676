import React from 'react';
import './CreditaHome.css';

const CreditaHome = () => {
    return (
        <div className="credita-home">
            <h1 className="credita-heading">How do we see ourselves?</h1>
            <div className="credita-content">
                <p className="credita-paragraph left-paragraph">
                    With a growing platform, Credita is committed to continuous innovation through the
                    addition of new features, increasing collateral options, and acquiring new markets—
                    more so those that have been less well-served with financial services. In light of placing
                    the user first in matters of empowerment, security, and transparency, Credita is
                    developing a financial ecosystem to serve all. We invite investors, users, and other
                    stakeholders to share this vision with us in revolutionizing how financial services are
                    delivered.
                </p>
                <p className="credita-paragraph right-paragraph">
                    Credita is about to revolutionize the face of financial interactions with the introduction
                    of a decentralized platform where users independently can lend and borrow on their
                    own terms. The use of blockchain technology ensures transparency, security, and
                    flexibility—shedding the rigid, exclusionary structures of traditional finance. Crypto and
                    NFTs as collateral together with customizable terms of loans provide users with the
                    power and independence they need in today's digital economy.
                </p>
            </div>
        </div>
    );
};

export default CreditaHome;
