import React, { useState } from "react";
import goldBond1 from "../../images/goldBond1.svg";
import goldBond2 from "../../images/goldBond2.svg";
import goldBond3 from "../../images/goldBond3.svg";
import InvestmentOption from "../InvestmentOption/InvestmentOption";
import "./CreditaFeatures.css";

const CreditaFeatures = () => {
  const [typeFilter, setTypeFilter] = useState("borrow"); // Default to "borrow"

  const handleTypeFilterChange = (type) => {
    setTypeFilter(type);
  };

  return (
    <div className="credita-container">
      {/* Heading added above the filter */}
      <h2 className="feature-heading">How does it all come together?</h2>

      <div className="radio-inputs">
        <label className="radio">
          <input
            type="radio"
            name="typeFilter"
            checked={typeFilter === "borrow"}
            onChange={() => handleTypeFilterChange("borrow")}
          />
          <span className="name">Borrow</span>
        </label>
        <label className="radio">
          <input
            type="radio"
            name="typeFilter"
            checked={typeFilter === "lend"}
            onChange={() => handleTypeFilterChange("lend")}
          />
          <span className="name">Lend</span>
        </label>
      </div>

      <div className="credita-content">
        <div
          className={`investment-options ${
            typeFilter === "borrow" ? "borrow-options" : "lend-options"
          }`}
        >
          {typeFilter === "borrow" && (
            <>
              <InvestmentOption
                imgSrc={goldBond1}
                imgWidth="80"
                imgHeight="40"
                heading="Safe"
                description="Held in demat form"
              />
              <InvestmentOption
                imgSrc={goldBond2}
                imgWidth="80"
                imgHeight="40"
                heading="No charges"
                description="No management fees or other charges. Gold ETFs & mutual funds charge up to ~1% as expense ratios."
              />
            </>
          )}

          {typeFilter === "lend" && (
            <>
              <InvestmentOption
                imgSrc={goldBond3}
                imgWidth="80"
                imgHeight="40"
                heading="Better than other forms of gold"
                description="No hidden charges or GST. Some forms of gold like digital gold incur a 3% GST + 3%+ spread on selling."
              />
              <InvestmentOption
                imgSrc={goldBond2}
                imgWidth="80"
                imgHeight="40"
                heading="No charges"
                description="No management fees or other charges. Gold ETFs & mutual funds charge up to ~1% as expense ratios."
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreditaFeatures;
