import React from 'react';
import './UserDetails.css';

const UserDetails = ({ user, totalTransactions, totalLends, totalBorrows }) => {
    const isKYCCompleted = true; // Assuming `kycCompleted` is a boolean in the `user` object

    return (
        <div className="user-details">
            <div className="user-photo">
                <img src={user.profilePhoto} alt="Profile" className="profile-photo" />
            </div>
            <div className="user-info">
                <p><strong>Username:</strong> {user.username}</p>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>KYC:</strong>
                    {isKYCCompleted ? (
                        <span className="kyc-status kyc-completed">Completed</span>
                    ) : (
                            <button className="complete-kyc-button">Complete Now</button>
                    )}
                </p>
            </div>
            <div className="user-stats">
                {totalTransactions !== undefined && <p><strong>Total Transactions:</strong> {totalTransactions}</p>}
                {totalLends !== undefined && <p><strong>Total Lends:</strong> ${totalLends}</p>}
                {totalBorrows !== undefined && <p><strong>Total Borrows:</strong> ${totalBorrows}</p>}
            </div>
        </div>
    );
};

export default UserDetails;
