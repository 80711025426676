import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CreditaPlatform from "../../components/CreditaPlatform/CreditaPlatform";
import CreditaInvestments from "../../components/CreditaInvestments/CreditaInvestments";
import CreditaHome from "../../components/CreditaHome/CreditaHome";
import FeedbackPopup from "../../components/FeebackPopUp/FeedbackPopup";

const Home = () => {
  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    const feedbackGiven = localStorage.getItem("feedbackGiven");
    if (!feedbackGiven) {
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 60000); // 3 minutes = 180000ms
      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, []);

  const closePopup = () => {
    setShowPopup(false);
    localStorage.setItem("feedbackGiven", "true"); // Store that the feedback popup was shown
  };
  return (
    <>
      <Container fluid>
        <Row>
          <Col xs={12}>
          {showPopup && <FeedbackPopup onClose={closePopup} />}
            <CreditaPlatform />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CreditaInvestments />
          </Col>
          <Col xs={12}>
            <CreditaHome />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Home;
