import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import "./Arena.css";
import config from "../../config";

const Arena = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [collateralLogos, setCollateralLogos] = useState([]);
  const [filters, setFilters] = useState({ asset: "All assets", type: "borrow" });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [requests, logos] = await Promise.all([
          fetch(`${config.baseUrl}/v1/credita/requests`, {
            headers: { accept: "application/json" },
          }).then((res) => res.json()),
          fetch("/data/cryptoData.json").then((res) => res.json()),
        ]);

        const mappedRequests = requests.map((request) => ({
          ...request,
          type: request.type === "LEND" ? "BORROW" : "LEND",
        }));

        setCryptoData(mappedRequests);
        setCollateralLogos(logos);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const filteredData = useMemo(() => {
    let data = cryptoData;
    if (filters.asset !== "All assets") {
      data = data.filter((deal) => deal.collateral.includes(filters.asset));
    }
    if (filters.type) {
      const targetType = filters.type.toUpperCase() === "BORROW" ? "LEND" : "BORROW";
      data = data.filter((deal) => deal.type === targetType);
    }
    return data;
  }, [cryptoData, filters]);

  const updateFilter = (key, value) => setFilters({ ...filters, [key]: value });

  const getLogoUrl = (collateral) => {
    const match = collateralLogos.find((crypto) =>
      crypto.name.toLowerCase().includes(collateral.toLowerCase())
    );
    return match?.img || "/default-logo.png";
  };

  const parseCollateral = (collateral) => {
    if (collateral.startsWith("{") && collateral.endsWith("}")) {
      return collateral.slice(1, -1).split(",");
    }
    return [collateral];
  };

  const buttonTexts = {
    borrow: "Lend",
    lend: "Borrow",
  };

  if (loading) return <div className="loader">Loading...</div>;

  return (
    <div className="arena-container">
      <header className="arena-header">
        <h2>Borrow and Lend</h2>
        <div className="arena-filters">
          {["borrow", "lend"].map((type) => (
            <button
              key={type}
              className={`filter-button ${filters.type === type ? "active" : ""}`}
              onClick={() => updateFilter("type", type)}
            >
              {buttonTexts[type]}
            </button>
          ))}
          <select
            className="arena-dropdown"
            value={filters.asset}
            onChange={(e) => updateFilter("asset", e.target.value)}
          >
            <option value="All assets">All assets</option>
            {["BTC", "ETH", "SOL"].map((asset) => (
              <option key={asset} value={asset}>
                {asset}
              </option>
            ))}
          </select>
        </div>
      </header>
      <table className="arena-table">
        <thead>
          <tr>
            <th>User</th>
            <th>Amount</th>
            <th>Duration</th>
            <th>Interest (%)</th>
            <th>Collateral</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((deal, index) => (
            <tr key={index}>
              <td>{deal.user_name}</td>
              <td>{deal.amount}</td>
              <td>{deal.duration}</td>
              <td>{deal.interest}</td>
              <td>
                <div className="collateral-cell">
                  {parseCollateral(deal.collateral).map((collateral, idx) => (
                    <div key={idx} className="collateral-item">
                      <img
                        src={getLogoUrl(collateral)}
                        alt={collateral}
                        loading="lazy"
                        className="collateral-logo"
                      />
                      {collateral}
                    </div>
                  ))}
                </div>
              </td>
              <td>
                <Link to={`/request/${deal.order_id}`} state={deal}>
                  {deal.type.toLowerCase()}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Arena;
