import React, { useState, useEffect } from 'react';
import './LendRequest.css';

const LendRequest = ({ amount, setAmount, duration, setDuration, interestRate, setInterestRate, collateral, setCollateral }) => {
  const [cryptoData, setCryptoData] = useState([]);

  useEffect(() => {
    // Fetch crypto data from JSON file
    fetch('/data/borrowCrypto.json')
      .then((response) => response.json())
      .then((data) => setCryptoData(data))
      .catch((error) => console.error('Error fetching crypto data:', error));
  }, []);

  const handleCollateralChange = (event) => {
    const { value, checked } = event.target;
    setCollateral((prevCollateral) => {
      if (checked) {
        return [...prevCollateral, value];
      } else {
        return prevCollateral.filter((item) => item !== value);
      }
    });
  };

  return (
    <div className="lend-request">
      <label className="bold-text">Amount to Lend (USD)</label>
      <input
        type="number"
        placeholder="Enter amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
      />
      <label className="bold-text">Duration (Months)</label>
      <input
        type="number"
        placeholder="Enter duration"
        value={duration}
        onChange={(e) => setDuration(e.target.value)}
      />
      <label className="bold-text">Interest Rate: {interestRate}%</label>
      <input
        type="range"
        min="1"
        max="100"
        value={interestRate}
        onChange={(e) => setInterestRate(e.target.value)}
      />
      <div className="collateral-options">
        <label className="bold-text">Collateral Accepted</label>
        <div className="collateral-list">
          {cryptoData.map((option) => (
            <div className="collateral-item" key={option.name}>
              <label>
                <input
                  type="checkbox"
                  value={option.name}
                  checked={collateral.includes(option.name)}
                  onChange={handleCollateralChange}
                />
                <img src={option.logo} alt={option.name} width="20" height="20" />
                {option.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LendRequest;
