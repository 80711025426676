import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import creditawhite from "../../images/creditawhite.png";
import "./FootBar.css";

const FootBar = () => {
  return (
    <footer className="footbar p-4 text-white">
      <Container>
        <div>
          <div>
            <Row className="justify-content-between">
              <Col xs={6}>
                <img src={creditawhite} alt="credita logo" />
                <div className="mt-2 font-style">
                  <span>Support portal</span>
                </div>
              </Col>
              <Col className="text-end" xs={6}>
                <ul className="list-unstyled">
                  <li className="d-inline me-3">TOS</li>
                  <li className="d-inline me-3">About Us</li>
                </ul>
              </Col>
            </Row>
            <Row className="mt-3 font-style">
              <Col xs={6}>
                <p>PARAGRAPH</p>
              </Col>
              <Col xs={6}>
                <p>PARAGRAPH</p>
              </Col>
            </Row>
            <div className="mt-3 font-style">
              <p>PARAGRAPH</p>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default FootBar;