import React from "react";
import CryptoPrices from "../../components/CryptoPricesCard/CryptoPrices";
import CreditaFeatures from "../../components/CreditaFeatures/CreditaFeatures";
import Arena from "../../components/ArenaCard/Arena";
import "./MarketPlace.css";

const MarketPlace = () => {
  return (
    <div className="marketplace-container">
      <div className="card-wrapper">
        <CryptoPrices />
      </div>
      <div className="card-wrapper">
        <Arena />
      </div>
      <div className="card-wrapper">
        <CreditaFeatures />
      </div>
    </div>
  );
};

export default MarketPlace;
