import React from "react";
import CryptoNewsCard from "../../components/CryptoNewsCard/CryptoNewsCard";
import NewsSection from "../../components/NewsSection/NewsSection";
import "./News.css"; // Import the CSS file

const News = () => {
  return (
    <div className="news-container">
      <div className="news-card-section">
        <CryptoNewsCard />
      </div>
      <div className="news-section">
        <NewsSection />
      </div>
    </div>
  );
};

export default News;
